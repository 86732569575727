<template>
  <Bar
    v-if="data"
    ref="chartRef"
    :chart-options="chartOptions"
    :chart-data="data"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :css-classes="cssClasses"
    :styles="myStyles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "BarChart",
  components: { Bar },
  computed: {
    myStyles() {
      const responsiveStyle = {
        position: "relative",
      };

      if (typeof this.width === "number") {
        responsiveStyle.width = `${this.width}px`;
      } else if (typeof this.width === "string") {
        responsiveStyle.width = this.width;
      }

      if (typeof this.height === "number") {
        responsiveStyle.height = `${this.height}px`;
      } else if (typeof this.height === "string") {
        responsiveStyle.height = this.height;
      }

      return Object.assign(responsiveStyle, this.styles);
    },
    chartOptions() {
      const options = {
        responsive: this.responsive,
        maintainAspectRatio: this.maintainAspectRatio,
        onClick: this.handleClick,
        plugins: this.plugins,
        indexAxis: this.horizontal ? "y" : "x",
      };

      if (this.logarithmic) {
        if (this.horizontal) {
          options.scales = {
            x: {
              type: "logarithmic",
              min: 1,
              max: Math.max(...this.data.datasets[0].data),
            },
          };
        } else {
          options.scales = {
            y: {
              type: "logarithmic",
              min: 1,
              max: Math.max(...this.data.datasets[0].data),
            },
          };
        }
      }

      return options;
    },
  },
  props: {
    title: {
      type: String,
      default: "Bar Chart",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    logarithmic: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({
        labels: ["January", "February", "March"],
        datasets: [
          { label: "Sample", backgroundColor: "#f87979", data: [40, 20, 12] },
        ],
      }),
      validator: function(value) {
        console.log('validating data value....')
        // Value must have labels and datasets
        if (!value.labels || !value.datasets) return false;

        // Labels must be an array
        if (!Array.isArray(value.labels)) return false;

        // Datasets must be an array
        if (!Array.isArray(value.datasets)) return false;

        // Each dataset must have a label, backgroundColor, and data
        for (const dataset of value.datasets) {
          if (!dataset.label || !dataset.backgroundColor || !Array.isArray(dataset.data)) {
            return false;
          }
        }
        // If we reach this point, the value is valid
        return true;
      }
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: [Number, String],
      default: 400,
    },
    height: {
      type: [Number, String],
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    maintainAspectRatio: {
      type: Boolean,
      required: false,
      default: true,
    },
    responsive: {
      type: Boolean,
      required: false,
      default: true,
    },
    plugins: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    title(newValue) {
      this.initTitle();
    },
  },
  created() {
    if (this.title) {
      this.initTitle();
    }
  },
  methods: {
    handleClick(point, event) {
      const item = event[0];
      if (item) {
        const datasetIndex = item.datasetIndex;
        const index = item.index;
        console.log("event", event);
        console.log(item);
        this.$emit("click", {
          datasetIndex,
          index,
          backgroundColor: this.data.datasets[datasetIndex].backgroundColor,
          value: this.data.datasets[datasetIndex].data[index],
          data: this.data.datasets[datasetIndex].dataObject
            ? this.data.datasets[datasetIndex].dataObject[index]
            : null,
        });
      }
    },
    initTitle() {
      this.plugins.title = { display: this.showTitle, text: this.title };
      this.plugins.legend = {
        display: this.showLegend,
      };
    },
  },
};
</script>